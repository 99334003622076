import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  // Checkbox,
  Typography,
} from "@mui/material";
import { useContext, useMemo } from "preact/hooks";
import {
  openWorkflowActionModal,
  workflowActionModalObject,
} from "../store/workflowActionModal";
import { assignedUsersObject } from "../store/assignedUserModalSignal";
import { TranslateContext } from "@denysvuika/preact-translate";

// const users = [
//   {
//     id: 1,
//     studentNumber: "123451",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "abdulaziz.alotaibi@classera.com",
//     name: "عبد العزيز بن خالد بن محمد العتيبي",
//   },
//   {
//     id: 2,
//     studentNumber: "123452",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "fahad.alqahtani@classera.com",
//     name: "فهد بن سلمان بن ناصر القحطاني",
//   },
//   {
//     id: 3,
//     studentNumber: "123453",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "turki.alshehri@classera.com",
//     name: "تركي بن فيصل بن عبد الرحمن الشهري",
//   },
//   {
//     id: 4,
//     studentNumber: "123454",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "naif.almutairi@classera.com",
//     name: "نايف بن عبد الله بن محمد المطيري",
//   },
//   {
//     id: 5,
//     studentNumber: "123455",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "khalid.alghamdi@classera.com",
//     name: "خالد بن عبد الرحمن بن صالح الغامدي",
//   },
//   {
//     id: 6,
//     studentNumber: "123456",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "saud.alsubaie@classera.com",
//     name: "سعود بن محمد بن عبد العزيز السبيعي",
//   },
//   {
//     id: 7,
//     studentNumber: "123457",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "abdullah.aldossari@classera.com",
//     name: "عبد الله بن فهد بن ناصر الدوسري",
//   },
//   {
//     id: 8,
//     studentNumber: "123458",
//     workflow: "مراحل سير عمل",
//     institution: "Classera",
//     email: "faisal.altamimi@classera.com",
//     name: "فيصل بن خالد بن عبد الرحمن التميمي",
//   },
// ];
function UserTable({ users }) {
  console.log(users);
  const { t } = useContext(TranslateContext);
  const memoizedUserRows = useMemo(() => {
    return users.map((user, index) => (
      <TableRow key={index}>
        {/* <TableCell align="center">
          <Checkbox />
        </TableCell> */}
        <TableCell>
          <Typography color="primary">{user.info.name}</Typography>
        </TableCell>
        <TableCell>{user.info.email}</TableCell>
        {/* <TableCell>{user.info.institution}</TableCell> */}
        <TableCell>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ borderRadius: "12px" }}
            onClick={() => {
              openWorkflowActionModal.value = true;
              workflowActionModalObject.value = {
                ...assignedUsersObject.value,
                user_id: user.id,
              };
            }}
          >
            {user.workflow_level_id}
          </Button>
        </TableCell>
        {/* <TableCell>{user.info.studentNumber}</TableCell> */}
      </TableRow>
    ));
  }, [users]);

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: 2 }}
    >
      <Table
        sx={{
          border: "1px solid #ececec",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <TableHead sx={{ backgroundColor: "#F9FAFA" }}>
          <TableRow>
            {/* <TableCell align="center">{}</TableCell> */}
            <TableCell align="left">{t("shared.name")}</TableCell>
            <TableCell align="left">{t("shared.email")}</TableCell>
            {/* <TableCell align="left">{t("shared.institution")}</TableCell> */}
            <TableCell align="left">{t("workflow.workflow")}</TableCell>
            {/* <TableCell align="left">{t("shared.studentNumber")}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>{memoizedUserRows}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserTable;
