import { getConfig } from "../config";

export const ENAR = ([primary, secondary]) => {
  const currentlanguage = getConfig().lang;
  const secondaryLanguage = getConfig().language.secondary;

  
  if (
    currentlanguage === secondaryLanguage &&
    secondary.length &&
    secondary !== 'null'
  ) {
    return secondary;
  }

  return primary;
};
