import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  assignedUsersObject,
  openAssignedUserModal,
} from "../store/assignedUserModalSignal";
import { lazy, Suspense, useContext, useEffect, useState } from "preact/compat";
import { TranslateContext } from "@denysvuika/preact-translate";
import { getWorkflowUsersList, getUserData } from "../services/workflowService";
import { NotificationContext } from "../context/NotificationContext";

const UserTable = lazy(() => import("./Users"));

function AssignedUserModal() {
  const { t } = useContext(TranslateContext);
  const { showNotification } = useContext(NotificationContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (openAssignedUserModal.value) {
      const fetchUsers = async () => {
        setLoading(true);
        try {
          const response = await getWorkflowUsersList(
            assignedUsersObject.value.workflow_id
          );
          if (response.success) {
            const userIds = response.users.map((user) => user.user_id);
            const userInfoResponse = await getUserData(userIds);

            if (userInfoResponse.success) {
              const userInfoMap = userInfoResponse.data.data.reduce(
                (acc, user) => {
                  acc[user.id] = user;
                  return acc;
                },
                {}
              );

              const updatedUsers = response.users.map((user) => ({
                ...user,
                info: userInfoMap[user.user_id] || {},
              }));
              setUsers(updatedUsers);
            } else {
              console.error(
                "Failed to fetch user info:",
                userInfoResponse.message
              );
            }
          } else {
            console.error("Failed to fetch workflow users:", response.message);
          }
        } catch (error) {
          console.error("An error occurred while fetching users:", error);
          showNotification({
            message: t("shared.somethingBad"),
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      };

      fetchUsers();
    }
  }, []);

  return (
    <Dialog
      open={openAssignedUserModal.value === true}
      onClose={() => {
        openAssignedUserModal.value = false;
        assignedUsersObject.value = { workflow_id: null };
      }}
      fullWidth
      maxWidth="md"
      disableEnforceFocus
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ backgroundColor: "#F9FAFA" }}
      >
        <Typography variant="h6">{t("workflow.assignedUsers")}</Typography>
        <IconButton
          onClick={() => {
            openAssignedUserModal.value = false;
            assignedUsersObject.value = { workflow_id: null };
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          backgroundColor: "#F2F5F8",
        }}
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <CircularProgress />
              </Box>
            }
          >
            <UserTable users={users} />
          </Suspense>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AssignedUserModal;
