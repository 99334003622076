import { getToken } from "../store/tokenSignal";
import { getConfig } from "../config";


export const apiClient = async (
  endpoint,
  options = {},
  isProtected = false
) => {
  // Check if the endpoint is an external API
  if (endpoint.startsWith("https")) {
    // Send the request as it is for external APIs
    return await fetch(endpoint, {
      ...options,
      headers: new Headers(options.headers || {}),
    });
  }

  const headers = new Headers(options.headers || {});
  
  if (isProtected) {
    headers.set("Authorization", `${getToken.value}`);
  }

  if (options.param) {
    const searchParams = new URLSearchParams(options.param).toString();
    endpoint += `?${searchParams}`;
    delete options.param;
  }

  try {
    const response = await fetch(`${getConfig().API_Path}${endpoint}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.log(error,'error')
    if (error.message.includes('400')) {
      console.log('Unauthorized')
      throw new Error('Unauthorized');
    }
    throw error;
  }
};
