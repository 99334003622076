// config.js

// Default configuration
const defaultConfig = {
  API_Path: "https://workflow-api-stg.classera.com/",
  lang: "en",
  language: {
    primary: "en",
    secondary: "ar",
  },
  workflow_type : ["users", "payment_receipt"],
  user: {
    name: "Admine",
    email: "Admin@classera.com",
    tenant_id: 1,
    sub_tenant_id: 0,
    tenant_user_id: 20,
    role_id: 1,
  },
  tenant: {
    key: "sLM7cqvSGGR4BAKRtyY5wv1ceNSqbvo8",
    secret: "35ffssJ0w4ic2JoJMq1XNSAC1E9",
  },
  getUsersUrl : 'https://stm-dev-api.classera.com/api/users',
  getAdminsUrl : 'https://stm-dev-api.classera.com/api/users',
  authToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMmRiNzUwNTNmNWViMDk2OTdmOWFjNjlkMmU5OTBlODJkYjE1MWQzOTdhZDU3NGU1ZmJmYmZmYTViNjZhZGVhNDM3ODU2OWVjYWM2MmRjOGEiLCJpYXQiOjE3MTk5MjMxOTcuMDk0NzgzLCJuYmYiOjE3MTk5MjMxOTcuMDk0Nzg2LCJleHAiOjE3NTE0NTkxOTcuMDQ1MzU2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.dCFgvbbOtP7Lbdo0BvERsFB0khD5PTlAXZyXqFGNUV9fC0x1Apl8WaRNBBFDTmbQ2T4U1RU1QnSy7zmH57P0rl1fRp3IPbyd1YP9IFKdqbVVku3yZQbxqwV6lsNWdvSaCsw25UJ3qzw44F6UVocNSaZZyAS0MPUq3OSr4tqclUGQa4e_XYvqggSu67Gr1eVGbASlmN2-PG-8GEvarm5EZjns96HOjFxsWLdCOczCsd4dxiJnhXmvFhGsIFcbh1KytyQF9tlP9VDCqb_p2qztBGSG5y6mAK5Lm-0PA_XKijzFSh3bb6hrzGW_70Hox6kqW-ktJX3LW4QZtuw78WFHgAQ63JdJ2Rmf3Lu5uDQrg16SxaHEPLf82YWOtlisOlA4IJZUI0rIsexAkrKYBjOH_GDeyg2X9tS_0ISu9ooveOXQKz1MRHZjjqWYIqIunqDfc6ixbjW6AEiU33Gk87U6EOZySdAon1YXArShbv04Yk-Q2g9jmxUPxlL3c3l-4svouAiM50vUA0kRiEBNX63FEuP2z2VGVFPtbmYrz_t-NlZAM-VXxrCGV9D_KguSRVfF9B0P0JZGo48qJGdxi1zdbMBG65bMkfeu-KiQ7LPOv--OQED-dNbTd6_3xLmPqswxYzFDpJbCZMUiU2rwflZkdD-tkjvXI_J-wx7uQtTRGHI",
  roles: [
    { id: "6", label: "roles.6s" },
    { id: "4", label: "roles.4s" },
    { id: "20", label: "roles.20s" },
  ],
  notifications : {
    backgroundColor : {
      info: "#2196F3", 
      success : "#3e913e",
      warning: "#FF9800",
      error : "#ee404c",
    },
    textColor: {
      info: "#ffffff",
      success: "#ffffff",
      warning: "#000000",
      error: "#ffffff",
    },
    showCloseButton : false,
    showIcon : false
  }
};

// Create a mutable config object
let config = {
  ...defaultConfig,
};

// Function to update config
export function updateConfig(newConfig) {
  config = {
    ...config,
    ...newConfig,
  };
  return config;
}

export function getConfig() {
  return config;
}