import { createContext } from "preact";
import { useState, useCallback, useRef } from "preact/hooks";
import { Alert, Snackbar } from "@mui/material";

export const NotificationContext = createContext(null);

export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false);
  const notificationRef = useRef({
    message: "",
    type: /** @type {'error' | 'warning' | 'info' | 'success'} */ ("info"),
  });

  const showNotification = useCallback(({ message, type = "info" }) => {
    // @ts-ignore
    notificationRef.current = { message, type };
    setOpen(true);
  }, []);

  const handleClose = useCallback((_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  }, []);

  const contextValue = useRef({ showNotification }).current;

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiSnackbar-root": {
            minWidth: "auto",
          },
          "& .MuiAlert-root": {
            fontFamily: "'Tajawal', sans-serif",
          },
          "& .MuiAlert-message": {
            fontFamily: "'Tajawal', sans-serif",
          },
        }}
      >
        <Alert
          severity={notificationRef.current.type}
          sx={{ minWidth: "300px" }}
          icon={false}
        >
          {notificationRef.current.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
}
