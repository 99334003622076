import { TranslateContext } from "@denysvuika/preact-translate";
import {
  Popover,
  TextField,
  Box,
  Typography,
  Button,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useContext, useState } from "preact/hooks";
import { createWorkflow, updateWorkflow } from "../services/workflowService";
import { NotificationContext } from "../context/NotificationContext";
import CloseIcon from '@mui/icons-material/Close';
import { getConfig } from "../config";

function WorkflowEditor({ anchorEl, close, onSuccess, workflow }) {
  const { t } = useContext(TranslateContext);
  const { showNotification } = useContext(NotificationContext);
  const open = Boolean(anchorEl);
  const id = open ? "workflow-popover" : undefined;

  const [formData, setFormData] = useState({
    type: workflow?.reference_type || "",
    role: workflow?.role_id?.toString() || "",
    title: workflow?.title_primary || "",
    titleSecondary: workflow?.title_secondary || "",
    assignToNewUsers: workflow?.assign_to_new_users || false,
    assignOnFormSubmit: Boolean(workflow?.submitted_reference),
    submittedReference: workflow?.submitted_reference || "",
    submittedId: workflow?.submitted_id?.toString() || "",
    descrption : workflow?.descrption || "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
      ...(name === 'assignOnFormSubmit' && !checked ? {
        submittedReference: '',
        submittedId: ''
      } : {})
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let response;
      if (workflow?.id) {
        response = await updateWorkflow(workflow.id, formData);
      } else {
        response = await createWorkflow(formData);
      }

      if (response.success) {
        showNotification({ 
          message: t(workflow?.id ? "workflow.updateSuccess" : "workflow.createSuccess"), 
          type: "success" 
        });
        onSuccess();
        close();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showNotification({ 
        message: t(workflow?.id ? "workflow.updateError" : "workflow.createError"), 
        type: "error" 
      });
      console.error("Error handling workflow:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = formData.type && formData.role && formData.title && 
    (!formData.assignOnFormSubmit || (formData.submittedReference && formData.submittedId));

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 2, width: "300px" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">
            {workflow?.id ? t("workflow.updateWorkflow") : t("workflow.createWorkflow")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={close}
            size="small"
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Users Dropdown */}
        <TextField
          select
          label={t("shared.type")}
          name="type"
          value={formData.type}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
          sx={{ mb: 2 }}
        >
          {getConfig().workflow_type.map((type) => (
            <MenuItem key={type} value={type}>{t(`shared.${type}`)}</MenuItem>
          ))}
        </TextField>

        {/* Role Dropdown */}
        <TextField
          select
          label={t("shared.role")}
          name="role"
          value={formData.role}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
          sx={{ mb: 2 }}
        >
          {getConfig().roles.map(role => (
            <MenuItem key={role.id} value={role.id}>{t(role.label)}</MenuItem>
          ))}
        </TextField>

        {/* Title */}
        <TextField
          label={t("shared.title")}
          name="title"
          value={formData.title}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          required
          sx={{ mb: 2 }}
        />

        {/* Title (Arabic) */}
        <TextField
          label={`${t("shared.title")} (${t(
            `language.${getConfig().language.secondary}`
          )})`}
          name="titleSecondary"
          value={formData.titleSecondary}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
        />

        <TextField
          label={t("shared.description")}
          name="descrption"
          value={formData.descrption}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline 
          rows={4}
          required
          sx={{ mb: 2 }}
        />

          

        {/* Create Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit}
          disabled={!isFormValid || isSubmitting}
        >
          {t(workflow?.id ? "shared.update" : "shared.create")}
        </Button>
      </Box>
    </Popover>
  );
}

export default WorkflowEditor;
