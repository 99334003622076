import { h } from 'preact';
import ReactQuill from 'react-quill';
import { useDirection } from '../context/DirectionContext';

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  [{ direction: 'rtl' }],
  ['clean']
];

const defaultProps = {
  theme: 'snow',
  placeholder: 'Type email here...',
};

const RichTextEditor = ({ 
  value, 
  onChange, 
  placeholder = defaultProps.placeholder,
  theme = defaultProps.theme,
}) => {
  const { isRTL } = useDirection();

  const modules = {
    toolbar: {
      container: TOOLBAR_OPTIONS
    },
    clipboard: {
      matchVisual: false
    }
  };

  return (
    <div className="rich-text-editor-container" dir={isRTL ? 'rtl' : 'ltr'}>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        theme={theme}
        placeholder={placeholder}
        style={{ 
          direction: isRTL ? 'rtl' : 'ltr',
          textAlign: isRTL ? 'right' : 'left'
        }}
      />
    </div>
  );
};

// Add global styles for RTL support
const style = document.createElement('style');
style.textContent = `
  
  [dir="rtl"] .ql-editor {
    text-align: right;
  }
  
  [dir="rtl"] .ql-toolbar.ql-snow .ql-picker-label{
    text-align: left;
  }
`;
document.head.appendChild(style);

// Memoize the component to prevent unnecessary re-renders
export default RichTextEditor;
