import { Box, Typography, Dialog, IconButton, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMemo, useState, useEffect, useContext } from "preact/hooks";
import {
  openWorkflowActionModal,
  workflowActionModalObject,
} from "../store/workflowActionModal";
import { getWorkflowLevelList, approveWorkflowLevel, rejectWorkflowLevel } from "../services/workflowService";
import { ENAR } from "../utils/maskENAR";
import { NotificationContext } from "../context/NotificationContext";
import { TranslateContext } from "@denysvuika/preact-translate";


const WorkflowActionModal = () => {
  const { showNotification } = useContext(NotificationContext);
  const { t } = useContext(TranslateContext);
  // @ts-ignore
  const [workflowLevels, setWorkflowLevels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchWorkflowLevels = async () => {
      // @ts-ignore
      if (
        openWorkflowActionModal.value &&
        workflowActionModalObject.value.workflow_id
      ) {
        setIsLoading(true);
        try {
          // @ts-ignore
          const response = await getWorkflowLevelList(
            workflowActionModalObject.value.workflow_id, 3
          );
          if (response.success) {
            setWorkflowLevels(response.data);
            console.log(response.data);
          }
        } catch (error) {
          console.error("Error fetching workflow levels:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchWorkflowLevels();
  }, [openWorkflowActionModal.value, workflowActionModalObject.value.workflow_id]);

  const handleApproveLevel = async (userId, workflowLevelId) => {
    console.log(userId, workflowLevelId);
    try {
      const response = await approveWorkflowLevel(userId, workflowLevelId);
      if (response.success) {
        // Refresh the workflow levels after approval
        const updatedLevels = await getWorkflowLevelList(
           workflowActionModalObject.value?.workflow_id,
          userId
        );
        if (updatedLevels.success) {
          setWorkflowLevels(updatedLevels.data);
          showNotification({message: t("shared.updatedSuccessfully"), type: "success"});
        }
      }
    } catch (error) {
      if(error.message === "Unauthorized"){
        showNotification({message: t("shared.noPermissionsToDoThisAction"), type: "error"});
      } 
    }
  };

  const handleRejectLevel = async (userId, workflowLevelId) => {
    try {
      const response = await rejectWorkflowLevel(userId, workflowLevelId);
      if (response.success) {
        // Refresh the workflow levels after rejection
        const updatedLevels = await getWorkflowLevelList(
          workflowActionModalObject.value.workflow_id,
          userId
        );
        if (updatedLevels.success) {
          setWorkflowLevels(updatedLevels.data);
          showNotification({message: t("shared.updatedSuccessfully"), type: "success"});
        }
      }
    } catch (error) {
      console.log(error,'error in actions')
      if(error.message === "Unauthorized"){
        console.log('in here');
        
        showNotification({message: t("shared.noPermissionsToDoThisAction"), type: "error"});
      } 
    }
  };

  const handleActionClick = (level, action) => {
    if (action === "Approve") {
      handleApproveLevel(3, level.id);
    } else if (action === "Reject") {
      handleRejectLevel(3, level.id);
    }
  };

  const memoizedLevels = useMemo(
    () =>
      workflowLevels.map((levels, index) => (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          key={`group-${index}`}
          sx={{
            backgroundColor: "#F2F5F8",
            minHeight: "235px",
            maxHeight: "376px",
            height: "auto",
            width: "274px",
            borderRadius: 2,
            flex: "0 0 auto",
            p: 1,
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            justifyContent: levels.length < 3 ? "center" : "flex-start",
          }}
        >
          {levels.map((level, levelIndex) => (
            <Box
              key={`level-${level.level}-${levelIndex}`}
              sx={{
                backgroundColor: "#ffff",
                borderRadius: "8px",
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {ENAR([level.title_primary, level.title_secondary])}
              </Typography>

              {level.status === 1 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      color: "#4CAF50",
                      fontWeight: 500,
                      backgroundColor: "#E8F5E9",
                      padding: "4px 16px",
                      borderRadius: "50px",
                      display: "inline-flex",
                      alignItems: "center",
                      width: "fit-content",
                      fontSize: "0.875rem",
                    }}
                  >
                    {t("workflow.approved")}
                  </Typography>
                </Box>
              )}

              {level.status === 0 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      color: "#FF4D4F",
                      fontWeight: 500,
                      backgroundColor: "#FDEDED",
                      padding: "4px 16px",
                      borderRadius: "50px",
                      display: "inline-flex",
                      alignItems: "center",
                      width: "fit-content",
                      fontSize: "0.875rem",
                    }}
                  >
                    {t("workflow.rejected")}
                  </Typography>
                </Box>
              )}

              {level.status === -1 && (
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    sx={{
                      backgroundColor: "#E8F5E9",
                      borderRadius: "4px 0 0 4px",
                      color: "#4CAF50",
                      textTransform: "none",
                    }}
                    onClick={() => handleActionClick(level, "Approve")}
                  >
                    {t("workflow.approve")}
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    fullWidth
                    sx={{
                      backgroundColor: "#FDEDED",
                      borderRadius: "0 4px 4px 0",
                      color: "#FF4D4F",
                      textTransform: "none",
                    }}
                    onClick={() => handleActionClick(level, "Reject")}
                  >
                    {t("workflow.reject")}
                  </Button>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )),
    [workflowLevels]
  );

  return (
    <Dialog
      open={openWorkflowActionModal.value}
      onClose={() => {
        openWorkflowActionModal.value = false;
        workflowActionModalObject.value = {
          workflow_id: null,
          user_id: null
        };
        setWorkflowLevels([]);
      }}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: "90vh",
          m: 2,
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          width: "100%",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h5">
            {t("workflow.workflowAction")}
          </Typography>
          <IconButton
            onClick={() => {
              openWorkflowActionModal.value = false;
              workflowActionModalObject.value = {
                workflow_id: null,
                user_id: null
              };
              setWorkflowLevels([]);
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            border: "1px solid #ececec",
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            gap={2}
            sx={{
              backgroundColor: "#ffff",
              p: 2,
              overflowX: "auto",
              whiteSpace: "nowrap",
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : memoizedLevels}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WorkflowActionModal;
