import {
  Popover,
  Box,
  Typography,
  Avatar,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "preact/hooks";
import { TranslateContext } from "@denysvuika/preact-translate";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationContext } from "../context/NotificationContext";
import {
  addWorkflowLevelOwner,
  getOwnersList,
} from "../services/workflowService";
import debounce from "lodash/debounce";

const AddOwnersPopover = ({
  anchorEl,
  onClose,
  workflowId,
  levelId,
  onLevelUpdate,
}) => {
  const { t } = useContext(TranslateContext);
  const { showNotification } = useContext(NotificationContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [owners, setOwners] = useState([]);
  const [loadingOwners, setLoadingOwners] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const scrollRef = useRef(null);

  const fetchOwners = async (pageNum, search = "") => {
    try {
      setLoadingOwners(true);
      const response = await getOwnersList({
        current_page: pageNum,
        search,
      });

      if (response.success) {
        if (pageNum === 1) {
          setOwners(response.data.data);
        } else {
          setOwners((prev) => [...prev, ...response.data.data]);
        }
        setHasMore(response.data.current_page < response.data.last_page);
      }
    } catch (error) {
      showNotification({
        message: t("shared.somethingBad"),
        type: "error",
      });
    } finally {
      setLoadingOwners(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      setPage(1);
      fetchOwners(1, query);
    }, 500),
    [] // Empty dependency array to ensure it is created only once
  );

  useEffect(() => {
    fetchOwners(1);
    return () => {
      debouncedSearch.cancel(); // Cleanup on unmount
    };
  }, [debouncedSearch]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query); // Call the debounced function
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollHeight - scrollTop <= clientHeight * 1.5 &&
      !loadingOwners &&
      hasMore
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchOwners(nextPage, searchQuery);
    }
  };

  const handleOwnerSelect = (owner) => {
    setSelectedOwner(owner);
  };

  const handleAddOwners = async () => {
    if (!selectedOwner) {
      showNotification({
        message: t("shared.pleaseSelectOwner"),
        type: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await addWorkflowLevelOwner(
        selectedOwner.id,
        workflowId,
        levelId
      );

      if (response.success) {
        onLevelUpdate?.();
        showNotification({
          message: t("workflow.ownerAddedSuccess"),
          type: "success",
        });
        onClose();
      } else {
        showNotification({
          message: response.message || t("shared.failedToAddOwner"),
          type: "error",
        });
      }
    } catch (error) {
      showNotification({ message: t("shared.somethingBad"), type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={{ width: 300 }}>
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{t("workflow.addOwners")}</Typography>
          <IconButton size="small" onClick={onClose} sx={{ color: "#afbacf" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            size="small"
            placeholder={t("shared.search")}
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ mb: 2 }}
          />

          <Box
            ref={scrollRef}
            onScroll={handleScroll}
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {owners.map((owner) => {
              // Find the role based on active_role
              const role = owner.roles.find(
                (role) => role.id === owner.active_role
              );
              const roleName = role ? role.name : ""; // Get the role name or an empty string if not found

              return (
                <Box
                  key={owner.id}
                  onClick={() => handleOwnerSelect(owner)}
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Change to column to stack name and role
                    alignItems: "flex-start",
                    py: 1,
                    px: 2,
                    cursor: "pointer",
                    backgroundColor:
                      selectedOwner?.id === owner.id
                        ? "#e3f2fd"
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        selectedOwner?.id === owner.id ? "#e3f2fd" : "#f8f9fa",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        mr: 1,
                        bgcolor: "#2196f3",
                      }}
                    >
                      {owner.name.charAt(0).toUpperCase()}
                    </Avatar>
                    <Box display="flex" flexDirection="column">
                      <Typography>{owner.name}</Typography>
                      {roleName && ( // Display role name if it exists
                        <Typography variant="body2" sx={{ color: "gray" }}>
                          {roleName}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}

            {loadingOwners && (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <CircularProgress size={24} />
              </Box>
            )}

            {owners.length === 0 && !loadingOwners && (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography>{t("shared.noResults")}</Typography>
              </Box>
            )}
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={handleAddOwners}
            disabled={loading || !selectedOwner}
            sx={{ mt: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("shared.add")
            )}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default AddOwnersPopover;
