import { h, render } from 'preact';
import { TranslateProvider } from '@denysvuika/preact-translate';
import { DirectionProvider } from './context/DirectionContext';
import App from './App';
import { getConfig, updateConfig } from './config';
import './styles/main.scss';

// Development render - will only run in dev mode
if (import.meta.env.DEV) {
  const devElement = document.createElement('div');
  devElement.id = 'app';
  document.body.appendChild(devElement);
  
  render(
    <TranslateProvider root="locales" lang={getConfig().lang}>
      <DirectionProvider defaultLanguage={getConfig().lang}>
        <App />
      </DirectionProvider>
    </TranslateProvider>,
    devElement
  );
}

// Add this function to load fonts
function loadFonts() {
  const link = document.createElement('link');
  link.href = 'https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap';
  link.rel = 'stylesheet';
  document.head.appendChild(link);

  const link2 = document.createElement('link');
  link2.href = "https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css";
  link2.rel = 'stylesheet';
  document.head.appendChild(link2);
}

// will be used in Angular
export function initWorkflow(elementId, options = {}) {
  console.log("initWorkflow", elementId, options);
  loadFonts();
  options = options || {};
  
  const targetElement = document.getElementById(elementId);
  if (!targetElement) return;

  targetElement.innerHTML = '';

  updateConfig(options);

  const language = options.lang;
  const localesPath = options.localesPath || 'locales';
  
  render(
    <TranslateProvider root={localesPath} lang={language}>
      <DirectionProvider defaultLanguage={language}>
        <App />
      </DirectionProvider>
    </TranslateProvider>,
    targetElement
  );

  return () => {
    render(null, targetElement);
  };
}

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.initWorkflow = initWorkflow;
}