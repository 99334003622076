import { createContext } from "preact";
import { useState, useCallback, useRef } from "preact/hooks";
import { Alert, Snackbar } from "@mui/material";
import { getConfig } from "../config";

export const NotificationContext = createContext(null);

export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false);
  const notificationRef = useRef({
    message: "",
    type: /** @type {'error' | 'warning' | 'info' | 'success'} */ ("info"),
  });

  const showNotification = useCallback(({ message, type = "info" }) => {
    // @ts-ignore
    notificationRef.current = { message, type };
    console.log(getConfig().notifications.showIcons);
    setOpen(true);
  }, []);

  const handleClose = useCallback((_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  }, []);

  const contextValue = useRef({ showNotification }).current;

  const alertStyles = {
    minWidth: "344px",
    ...(getConfig().notifications.backgroundColor && getConfig().notifications.backgroundColor[notificationRef.current.type] ? {
      backgroundColor: getConfig().notifications.backgroundColor[notificationRef.current.type],
    } : {}),
    ...(getConfig().notifications.textColor[notificationRef.current.type] ? {
      color: getConfig().notifications.textColor[notificationRef.current.type],
    } : {}),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiSnackbar-root": {
            minWidth: "auto",
          },
          "& .MuiAlert-root": {
            fontFamily: "'Tajawal', sans-serif",
          },
          "& .MuiAlert-message": {
            fontFamily: "'Tajawal', sans-serif",
          },
        }}
      >
        <Alert
          onClose={
            getConfig().notifications.showCloseButton ? handleClose : undefined
          }
          severity={notificationRef.current.type}
          sx={alertStyles}
          icon={getConfig().notifications.showIcon ? null : false}
        >
          {notificationRef.current.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
}
