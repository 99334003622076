import { getConfig } from "../config";

export const maskArabicNumbers = (num) => {
  const language = getConfig().lang;

  if (language === "ar") {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return num.toString().replace(/\d/g, (digit) => arabicNumbers[digit]);
  }

  return num;
};
