import { h } from "preact";
import { useDirection } from "./context/DirectionContext";
import WorkflowListPage from "./pages/WorkflowList";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState, useMemo } from "preact/hooks";
import { fetchTenantToken, getUserToken } from "./services/authService";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import { NotificationProvider } from "./context/NotificationContext";

const cacheRtl = createCache({
  key: "mui-rtl",
  stylisPlugins: [rtlPlugin],
});

const cacheLtr = createCache({
  key: "mui-ltr",
});

function useAuth() {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    loading: true,
    error: null,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchToken = async () => {
      try {
        const response = await fetchTenantToken();
        if (!isMounted) return;

        if (response.success) {
          const tokenRes = await getUserToken();
          if (!isMounted) return;

          if (tokenRes.success) {
            setAuthState({ isAuthenticated: true, loading: false, error: null });
          } else {
            throw new Error("User token fetch failed");
          }
        } else {
          throw new Error("Tenant token fetch failed");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching token:", error);
          setAuthState({ isAuthenticated: false, loading: false, error: error.message });
        }
      }
    };

    fetchToken();

    return () => {
      isMounted = false;
    };
  }, []);

  return authState;
}

function App() {
  const { isAuthenticated, loading, error } = useAuth();
  const { isRTL } = useDirection();

  const theme = useMemo(
    () =>
      createTheme({
        direction: isRTL ? "rtl" : "ltr",
        palette: {
          primary: {
            main: "#00a7b7",
          },
        },
        typography: {
          fontFamily: "'Tajawal', sans-serif",
        },
      }),
    [isRTL]
  );

  const cache = isRTL ? cacheRtl : cacheLtr;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated ? (
          <NotificationProvider>
            <WorkflowListPage />
          </NotificationProvider>
        ) : (
          <p>Authentication failed {error}</p>
        )}
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
